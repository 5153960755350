































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import { Core } from "@/store/core";
@Component({
    components: {},
    computed: {}
})

export default class Home extends Vue {
    private form: object = {
        "personal_id": null,
        "tel": ""
    }
    private userExist: boolean = false
    private password: string = ''
    private passwordConfirm: string = ''
    private user: any = {}
    private async checkUser() {
        let checked = await Core.postHttp(`/api/forgetpassword/`, this.form)
        if (checked.user) {
            this.userExist = true
            this.user = checked
        } else {
            alert('ไม่พบ user ');
        }
        console.log(checked);
    }

    private async changePassword() {
        let form = {
            user: this.user.user,
            password: this.password
        }
        let changePasswordUser = await Core.postHttp(`/user/account/changepassword/`, form)
        if (changePasswordUser) {
            alert('สำเร็จแล้ว')
            await this.$router.push('/')
        } else {
            alert('ไม่สามารถแก้ไขรหัสผ่านได้')
        }
    }

}
